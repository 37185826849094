<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if="mobile === false" src="../../../../../assets/images/banners/venture.png" alt="banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/venture.png" alt="banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/offer">Wróć do spisu usług</router-link>
		</template>

		<template #small-title>
			<h4>PRAWO GOSPODARCZE</h4>
		</template>

		<template #title>
			<h2>Venture Capital i Privater Equity</h2>
		</template>

		<template #text>
			<p>Specjalizujemy się w obsłudze struktur z udziałem funduszy inwestycyjnych typu private equity oraz venture capital. Znajomość specyfiki rynku oraz mechanizmów inwestowania pozwala nam wspierać naszych klientów w całym procesie inwestycji od jego planowania, aż po zamkniecie i nadzorowanie. Posiadamy doświadczenie w tworzeniu struktur inwestycyjnych  umożliwiających prowadzenie działalności w formie alternatywnych spółek inwestycyjnych</p>
		</template>

		<template #range>
			<h3>Zakres:</h3>
		</template>
		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">Tworzenie struktury inwestycyjnej,</li>
				<li class="offer__list-item">Kompleksowa obsługa funduszy inwestycyjnych,</li>
				<li class="offer__list-item">Prowadzenie i tworzenie dokumentacji,</li>
				<li class="offer__list-item">Proces inwestycyjny</li>
				<li class="offer__list-item">Badania due diligence spółek,</li>
				<li class="offer__list-item">Przygotowywanie umów inwestycyjnych,</li>
				<li class="offer__list-item">Uczestnictwo w negocjacjach,</li>
				<li class="offer__list-item">Reprezentacja przed UOKIK oraz KNF</li>
			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";

export default {
	mixins: [handleWith],
	components: {
		Offer
	},
	name: 'venture capital',
}
</script>